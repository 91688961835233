import * as React from "react";
import { useEffect, useState, useRef } from "react";
import styled, { keyframes, css } from "styled-components";
import "@fontsource/creepster";
import "@fontsource/berkshire-swash";
import { graphql } from "gatsby";

import GlobalStyles from "../styles/globalStyles";
import Game from "../components/game";
import Scoreboard from "../components/scoreboard";
import breakpoints from "../styles/breakpoints";
import { Witch } from "./aag2";

export interface Game {
  name: string;
  isFinished: boolean;
  result: Clown[];
}

export interface Clown {
  name: string;
  points: number;
}

interface GameList {
  id: string;
  gameList: Game[];
}
interface QueryData {
  data: {
    allSanityGames: { nodes: GameList[] };
    allSanityClown: { nodes: Clown[] };
  };
}

const fadeIn = keyframes`
  0% {
      opacity: 0;
  }

  100% {
      opacity: 1;
  }
}`;

const peakOut = keyframes`
  0% {
    transform: rotate(0deg);
  }  
  100% {
    transform: rotate(-25deg);
  }
}`;

const centered = `
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
`;

const StyledMain = styled.main`
  background-image: url("/skane-flagga.png");
  background-size: cover;
  background-attachment: fixed;
  background-position: 33%;

  @media ${breakpoints.tabletLandscape} {
    background-position: center center;
  }
`;

const Headline = styled.div`
  position: absolute;
  top: 10vh;
  left: 10vw;
  color: #fff;
  text-align: center;
  font-size: 2.5vw;

  text-transform: uppercase;
  font-family: monospace;

  @media ${breakpoints.tabletLandscape} {
    top: 10vh;
    right: 10vw;
    left: inherit;
  }
`;

const LandingSection = styled.section`
  position: relative;
  height: 100vh;
`;

const GameSection = styled.section`
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font-family: Arial, Helvetica, sans-serif;
  margin-bottom: 300px;
  position: relative;
  color: #fff;

  padding-top: 40px;
  padding-bottom: 80vh;

  h2 {
    text-transform: uppercase;
    color: #fff;
  }

  > div {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 4px;
    margin-right: 20px;
    margin-bottom: 20px;
    width: 29%;

    line-height: 1.5;

    padding: 10px;

    @media (max-width: 768px) {
      width: 100%;
      margin-right: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  ul {
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 0px;

    list-style: none;
  }

  > img {
    position: absolute;
    bottom: 0;
    right: 10vw;
    width: 300px;
  }
`;

const TowerWrapper = styled.div`
  position: absolute;
  top: 100vh;
  transform: translateY(-100%);
  width: 25vw;
  left: 40vw;
  max-width: 200px;

  @media ${breakpoints.tabletLandscape} {
    width: 12vw;
    left: 10vw;
  }
`;

const Tower = styled.img`
  width: 100%;
  height: auto;
  position: relative;
  z-index: 20;
`;

const HiddenClown = styled.img`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 10;

  transform-origin: bottom;
  transition: transform 0.2s ease-in;

  animation-fill-mode: forwards !important;
  animation: ${peakOut};
  animation-duration: 2000ms;

  animation-delay: 200ms;
  z-index: 12;

  /* &:first-of-type { */
  &.robin {
    width: 150%;
  }

  &.pontus {
    animation-delay: 400ms;
    z-index: 11;
    width: 90%;
    bottom: 25%;
  }

  &.carl {
    animation-delay: 600ms;
    z-index: 10;
    bottom: 50%;
    left: -10%;
  }
`;

const IndexPage = ({ data }: QueryData) => {
  const [initiated, setInitiated] = useState(false);
  const [finishedGames, setFinishedGames] = useState([]);
  const [upcomingGames, setUpcomingGames] = useState([]);

  const games = data?.allSanityGames?.nodes[0].gameList;
  const clowns = data?.allSanityClown?.nodes;

  games.map((game) => (game.name = game.name.split(" - Malmö")[0]));
  const audioRef = useRef();

  useEffect(() => {
    setFinishedGames(games.filter((game) => game.isFinished));
    setUpcomingGames(games.filter((game) => !game.isFinished));

    setTimeout(() => setInitiated(true), 3500);
  }, []);

  return (
    <>
      <GlobalStyles />
      <StyledMain>
        <LandingSection>
          <audio loop ref={audioRef}>
            <source src="/skratt.mp3" type="audio/mp3" />
            Your browser does not support the audio element.
          </audio>

          <Headline>
            <span>Clowns</span> <br />
            <h1>in Malmö Towns</h1>
          </Headline>

          <TowerWrapper>
            <Tower src="/turning-torso.png" />

            <HiddenClown className="robin" src="/robin.png" />
            <HiddenClown className="pontus" src="/pontus.png" />
            <HiddenClown className="carl" src="/carl.png" />
          </TowerWrapper>
          <Witch playAnimation={initiated} />
        </LandingSection>

        <GameSection>
          <div>
            <h2>Kommande spel</h2>
            {upcomingGames.map((game, index) => {
              return <Game game={game} key={`upcoming-${index}`} />;
            })}
          </div>

          <div>
            <h2>Avslutade spel</h2>
            {finishedGames.map((game, index) => {
              return <Game game={game} key={`finished-${index}`} />;
            })}
          </div>

          <div>
            <h2>Poängställning</h2>
            <Scoreboard games={finishedGames} clowns={clowns} />
          </div>

          <img src="/tombstone.png" />
        </GameSection>
      </StyledMain>
    </>
  );
};

export default IndexPage;

export const query = graphql`
  query MalmoQuery {
    allSanityGames(filter: { title: { eq: "Game List Malmö" } }) {
      nodes {
        id
        gameList {
          name
          isFinished
          result {
            name
          }
        }
      }
    }
    allSanityClown {
      nodes {
        name
        points
      }
    }
  }
`;
